import { GridFilterModel } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { action, computed, makeObservable, observable } from 'mobx';
import { ChangeEvent } from 'react';
import { API } from '~/api';
import { FragranceId } from '~/api/api.declaration';
import { GridStateModel } from '~/model/model.gridstate';
import { FragranceCreateDto, FragrancePhotoDto } from '~/shared/dto/admin/fragrance.dto';
import { FTSStrategy } from '~/shared/dto/paging.dto';
import { Async } from '~/shared/tools/async';
import { Singleton } from '../shared/service/service.base';
import { ApiService } from './service.api';
import { LocalStorageService } from './service.localstorage';
import { UIService } from './service.ui';

@Singleton()
export class FragranceService {
	readonly items = observable.array([] as API.FragranceItem[], { deep: false });
	readonly async = new Async();
	readonly gridState = new GridStateModel('fragrances');

	@observable totalCount = 0;
	@observable searchTime = undefined as undefined | number;
	@observable superIdStr: null | string = null;
	@observable searchStrategy: FTSStrategy = 'FTS_V1';

	private filterModel?: GridFilterModel = undefined;

	@computed get superId(): FragranceId | 0 {
		const i = this.superIdStr ? parseInt(this.superIdStr) : 0;
		return isFinite(i) && i > 0 ? (i as FragranceId) : 0;
	}

	@computed get years() {
		const now = new Date();
		const years = Array.from({ length: now.getFullYear() - 1879 }, (_, i) => ({
			id: new Date().getFullYear() - i,
			name: `${new Date().getFullYear() - i}`,
		}));
		return years;
	}

	constructor(
		private api: ApiService,
		private localStorage: LocalStorageService,
		private ui: UIService,
	) {
		this.searchStrategy = this.localStorage.getEnum('fragranceSearchStrategy', FTSStrategy, 'FTS_V1');
		makeObservable(this);
	}

	@action.bound changeSearchStrategy(strategy: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
		this.searchStrategy = strategy.target.value as FTSStrategy;
		this.localStorage.setItem('fragranceSearchStrategy', this.searchStrategy);
		this.reload();
	}

	@action.bound changeFilterModel(model: GridFilterModel) {
		this.filterModel = model;
		this.reload();
	}

	@action.bound setSuperId(id: number | null | undefined | string) {
		this.superIdStr = id ? id.toString() : null;
	}

	loadAndSubscribe = () => {
		this.reload();
		return this.gridState.subscribe(this.reload);
	};

	reload = () => {
		this.async.call(async () => {
			const { data } = await this.api.fragranceGetAll({
				filter: this.gridState.filterQuery,
				limit: this.gridState.pageSize,
				offset: this.gridState.offset,
				sort: this.gridState.sort,
				sortDir: this.gridState.sortDir,
				filterModel: JSON.stringify(this.filterModel?.items.map(i => ({ ...i, id: undefined }))),
				strategy: this.searchStrategy,
			});
			return () => {
				this.items.replace(data.items);
				this.totalCount = data.total;
				this.searchTime = data.time;
				this.superIdStr = null;
			};
		});
	};

	async getOne(id: number | undefined) {
		if (id === undefined) return;
		return this.async.exec(() => this.api.fragranceGetOne(id).then(resp => resp.data));
	}

	async getUrlInfo(url: string) {
		try {
			new URL(url);
			return this.async.exec(() => this.api.fragranceGetUrlTitle({ url }).then(resp => resp.data));
		} catch {}
	}

	save(id: number | undefined, data: FragranceCreateDto) {
		return this.async.exec(async () => {
			await this.uploadPhotos(data.hiddenPhotos);
			await this.uploadPhotos(data.publicPhotos);
			await this.uploadPhotos(data.preliminaryPhotos);
			if (id === undefined) {
				await this.api.fragranceCreate(data);
			} else {
				await this.api.fragranceUpdate(id, data);
			}
		});
	}

	delete = (id: number) => {
		return this.async.exec(() => this.api.fragranceDelete(id)).then(this.reload);
	};

	exportImages = () => {
		this.async.call(async () => {
			const resp = await this.api.fragranceExportImages();
			this.ui.storeFile({
				content: resp.data.csv,
				type: 'application/csv',
				name: `fragrances-images-${dayjs().format('YYYY-MM-DD-HH-mm')}.csv`,
			});
		});
	};

	postSuperId = (forFragrance: number | undefined) => {
		return this.async.call(async () => {
			if (!forFragrance) return;
			if (this.superId && forFragrance !== this.superId) {
				await this.api.fragranceSetSuperFragrance(forFragrance, { superFragranceId: this.superId });
				this.reload();
			}
		});
	};

	changePhotoComplaint = (forFragrance: number | undefined, fileId: string, clear: boolean) => {
		return this.async.call(async () => {
			if (!forFragrance) return;
			await this.api.fragranceChangePhotoComplaint(forFragrance, { fileId, clear });
		});
	};

	removeSuperId = (forFragrance: number | undefined) => {
		return this.async.call(async () => {
			if (!forFragrance) return;
			await this.api.fragranceSetSuperFragrance(forFragrance, { superFragranceId: null });
			this.reload();
		});
	};

	exportCSV() {
		this.async.call(async () => {
			const resp = await this.api.fragranceExportCSV({
				filter: this.gridState.filterQuery,
				limit: this.gridState.pageSize,
				offset: this.gridState.offset,
				sort: this.gridState.sort,
				sortDir: this.gridState.sortDir,
				filterModel: JSON.stringify(this.filterModel?.items.map(i => ({ ...i, id: undefined }))),
				strategy: this.searchStrategy,
			});
			this.ui.storeFile({
				content: resp.data.csv,
				type: 'application/csv',
				name: `fragrances-${dayjs().format('YYYY-MM-DD-HH-mm')}.csv`,
			});
		});
	}

	getExperiences(id: FragranceId) {}

	private async uploadPhotos(photos: FragrancePhotoDto[] | undefined) {
		if (!photos || photos.length === 0) return;
		const imgs = photos.map(i => i.url);
		await this.api.uploadImgs(imgs);
		for (let i = 0; i < imgs.length; i++) {
			photos[i].fileId = photos[i].fileId || imgs[i];
			photos[i].url = '';
		}
	}
}
